html {
    min-height: 100%;
    position: relative;
}

body {
    background-color: rgba(0, 0, 0, 0.89);
    letter-spacing: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
}

body, a, button, footer i {
   color: rgba(255, 255, 255, 0.641);
}

a{
    text-decoration:none ;
}

i {
    color: cyan;
}

.grocery-list {
    margin: auto;
    margin-top: 100px;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 200px;
}

.list {
   
    width: max-content;
    padding:30px 50px 50px 50px;
    border: 2px solid rgba(255, 255, 255, 0.308); ;
    border-radius: 10px;
   /*  --angle: 0deg;
	border: 4px solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 10s rotate linear infinite; */
}

/* @keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
 */


h2 {
    margin:0 0 50px 0;
    text-align: center;
}

ul {
    padding: 0;
}

li {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    line-height: 35px;
}

li div {
    flex:1;
    display: flex;
}

h4 {
    margin: 0;
}

.delete {
    margin-left: 5px;
}

button {
    background: none;
    border: none;
}

p {
    margin: 0;
    font-size: 14px;
}

.add-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

button[type="button"] {
    font-size: 25px ;
    border-radius: 50%;
    padding: 12px 13px 10px 13px;
    border: 1px solid cyan;
}

form {
    display: flex;
    justify-content: space-between;
}

input[type="text"] {
    width: 100%;
}

input[type="text"] , input[type="checkbox"] {
    background-color:rgba(255, 255, 255, 0.641) ;
}

input[type="checkbox"]:checked {
    background-color: rgba(0, 0, 0, 0.89); 
}

footer{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
}


@media screen and (max-width:678px) {

    *{
        font-size: 20px;
    }

    button[type="button"] {
        font-size: 12px ;
        border-radius: 50%;
        padding: 10px 11px 8px 11px;
        border: 1px solid cyan;
    }
    .grocery-list {
        margin: auto;
        margin-top: 50px;
    
    }

    li div {
        gap:5px;
    }

    footer {
        left: 0;
        transform: none;
        padding: 20px;
        gap: 20px;
    }

    footer p,footer a{
        font-size: 15px;
    }
}